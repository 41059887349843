<div class="row d-flex mx-0 align-items-center justify-content-center">
    <div class="col-12">
        <h5>Title</h5>
        <p></p>
    </div>
    <div class="col-12">
        <div class="row d-flex align-items-center justify-content-center">
            <div class="col">
                <button mat-button color="primary"><span class="mdi mdi-eye-circle-outline"></span> Open</button>
            </div>
            <div class="col">
                <button mat-button color="accent"><span class="mdi mdi-close-circle-outline"></span> Close</button>
            </div>
        </div>
    </div>
</div>