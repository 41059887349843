import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'customTimePipe'
})
export class CustomTimePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if(value!='' && value !=null){
      return moment(value,'hh:mm').format("hh:mm A");
    }else{
      return 'NA';
    }

  }
}
