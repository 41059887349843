import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import { CommonService } from '../../shared/common.service';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-status-table',
  templateUrl: './status-table.component.html',
  styleUrls: ['./status-table.component.css'],
  standalone: true,
  imports: [
    CommonModule,MatIconModule,SharedModule,TranslateModule
  ]
})
export class StatusTableComponent implements OnInit {
  public dialogData: any = {};
  public status_list:any=[];
  constructor(public cs: CommonService,private formBuilder: FormBuilder,private dialogRef: MatDialogRef<StatusTableComponent>, @Inject(MAT_DIALOG_DATA) data: any)
  {
    this.dialogData=data;
    this.getStatusList();

  }
  async getStatusList() {
    if(this.dialogData.module=='hr'){
    let postDta =
    {
      scheema: "hr", procname: "get_statuses",
      vals: [this.dialogData.Id]
    };
    let result: any = await lastValueFrom(this.cs.postData({ sourceid: "data/calldbproc", info: postDta }));
    this.status_list = result['data'];
  }else if(this.dialogData.module=='assets'){
    let postDta =
    {
      scheema: "assets", procname: "get_equip_transaction_statuses",
      vals: [this.dialogData.Id]
    };
    let result: any = await lastValueFrom(this.cs.postData({ sourceid: "data/calldbproc", info: postDta }));
    this.status_list = result['data'];
  }
  }
  closeform(type=0) {
    this.dialogRef.close({type:type});
   }
  ngOnInit(): void {

  }

}
