import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { RouterOutlet } from '@angular/router';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
@Component({
  selector: 'app-notficationsnack',
  standalone: true,
  imports: [
    CommonModule, SharedModule, RouterOutlet
  ],
  templateUrl: './notificationsnack.html',
  styleUrl: './notficationsnack.component.css'
})
export class NotficationsnackComponent { 
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
     console.log('passed on data==',data);
   }

}
