import { Pipe, PipeTransform, ApplicationRef } from '@angular/core';

@Pipe({
  name: 'split',
  pure: false
})
export class SplitPipe implements PipeTransform {
  constructor(public appref:ApplicationRef){

  }
  transform(text: string, by: string, index: number = 1) {
    if(text!=null && text !=''){
      let arr = text.split(by); // split text by "by" parameter
       // this.appref.tick();
      return arr // after splitting to array return wanted index

    }else{
      return []
    }

  }
}
